@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:wght@600&display=swap');
@import "@fontsource/inter"; /* Ensures Inter is loaded */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide scrollbar for all elements */
html, body {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  font-family: 'Inter', sans-serif;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

@layer utilities {
  .mission-heading {
    font-family: 'Roboto Serif', serif;
    font-weight: 600;
    font-size: 30px;
    line-height: 100%;
    letter-spacing: 0;
    color: #FEB600;
  }
  .scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none;  /* Chrome, Safari and Opera */
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
